import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/layout'
import Breadcrumb from "../components/Global/Breadcrumb"


function CollaborationsTemplate(props) {
    console.log(props)
    let data = props.data;

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>

                    <section>
                        <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>

                    <div className="container-cs m-auto py-16 about-content-editor" dangerouslySetInnerHTML={{ __html: data.allWpPage.nodes[0].content }} />

                    <section>
                        <div className="text-left" >
                            <div className="m-auto" style={{ maxWidth: "1280px" }}>
                                <div className="flex flex-wrap py-4">
                                    {data.allWpPage.nodes[0].collaborationsCpt && data.allWpPage.nodes[0].collaborationsCpt.collaborations.map((service, yndex) => (
                                        <div key={`service-${service.title}`} className="w-full md:w-1/3 flex relative">
                                            <Link to={`${service.cta.link}`} className="m-8 m-1 relative" >
                                                <div className="flex items-center pb-4 pt-4 justify-center">
                                                    <div className="w-3/4">
                                                        <h2 className="py-4 service-h4 text-center" style={{ lineHeight: "1.3", minHeight: "100px" }}>
                                                            {htmlDecode(service.title)}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div>
                                                    <img
                                                        src={service.image.sourceUrl}
                                                        alt={service.image.altText}
                                                        className="m-auto sc-m-img"
                                                    />
                                                </div>
                                                <div className="flex justify-center w-full">
                                                    <div className="main-btn-container pt-8">
                                                        <Link to={`${service.cta.link}`} className=" main-button-blue mt-4 md:mt-0">
                                                            {service.cta.text}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                        <div className="py-8 md:py-16 text-left mx-auto" style={{ maxWidth: "1280px" }} >
                            <h2 className="py-4 service-h4 text-center" style={{ lineHeight: "1.3", minHeight: "100px" }}>
                                {data.allWpPage.nodes[0].collaborationsCpt.otherCollaborations.title}
                            </h2>
                            <div className="flex flex-wrap py-4">
                                {data.allWpPage.nodes[0].collaborationsCpt && data.allWpPage.nodes[0].collaborationsCpt.otherCollaborations.colls.map((service, yndex) => (
                                    <div key={`other.service-${service.title}`} className="w-full md:w-1/5 flex relative">
                                        <a href={service.link}>
                                            <img
                                                src={service.image.sourceUrl}
                                                alt={service.image.altText}
                                                className="m-auto sc-m-img"
                                            />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                </div>
            </Layout>
        </div>
    );
}

export default CollaborationsTemplate


export const pageQuery = graphql`query GET_COLLABORATIONS($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    content
    breadcrumbAcf {
         breadcrumbName
        
       }
       collaborationsCpt{
        collaborations{
          title
          image{
            altText
            sourceUrl
            
          }
          cta{
            link
            text
          }
        }
        otherCollaborations{
          title
          colls{
            link
            image{
                altText
                sourceUrl
            }
          }
        }
      }
    }

    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`

